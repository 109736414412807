<template>
    <div class='app-container'>
        <el-table :data="tableData"  show-summary :header-cell-style='cellStyle' :cell-style="cellStyle" style="width: 100%;">
            <el-table-column prop="major" label="学科" min-width="100">
            </el-table-column>
            <el-table-column prop="total" label="专家数" min-width="100">
            </el-table-column>
            <el-table-column label="按职称分">
                <el-table-column prop="title1" label="正高级" min-width="100">
                </el-table-column>
                <el-table-column prop="title2" label="副高级" min-width="100">
                </el-table-column>
                <el-table-column prop="title3" label="中级" min-width="100">
                </el-table-column>
                <el-table-column prop="title4" label="其他" min-width="100">
                </el-table-column>
            </el-table-column>
            <!-- <el-table-column label="按学校等级分">
                <el-table-column prop="level1" label="985" min-width="100">
                </el-table-column>
                <el-table-column prop="level2" label="211" min-width="100">
                </el-table-column>
                <el-table-column prop="level3" label="双一流" min-width="100">
                </el-table-column>
                <el-table-column prop="level4" label="其他" min-width="100">
                </el-table-column>
            </el-table-column> -->
        </el-table>
    </div>
</template>

<script>
    export default {
        name: 'statisticExpert',
        data() {
            return {
                tableData: [{
                    major:"哲学",
                    total:613*3,
                    title1:278*3,
                    title2:281*3,
                    title3:25*3,
                    title4:29*3,
                    level1:308*3,
                    level2:198*3,
                    level3:67*3,
                    level4:40*3,
                },
                {
                    major:"经济学",
                    total:2970*3,
                    title1:1278*3,
                    title2:1147*3,
                    title3:296*3,
                    title4:249*3,
                    level1:1302*3,
                    level2:1003*3,
                    level3:405*3,
                    level4:260*3,
                },{
                    major:"法学",
                    total:2653,
                    title1:974,
                    title2:1140,
                    title3:501,
                    title4:38,
                    level1:974,
                    level2:1140,
                    level3:501,
                    level4:38,
                },{
                    major:"教育学",
                    total:2449*3,
                    title1:984*3,
                    title2:961*3,
                    title3:303*3,
                    title4:201*3,
                    level1:875*3,
                    level2:920*3,
                    level3:451*3,
                    level4:203*3,
                },{
                    major:"文学",
                    total:4417,
                    title1:1732,
                    title2:2349,
                    title3:300,
                    title4:36,
                    level1:1732,
                    level2:2349,
                    level3:3003,
                    level4:36,
                },{
                    major:"历史学",
                    total:456*3,
                    title1:198*3,
                    title2:176*3,
                    title3:60*3,
                    title4:22*3,
                    level1:187*3,
                    level2:189*3,
                    level3:41*3,
                    level4:39*3,
                },{
                    major:"理学",
                    total:21431*3-4000,
                    title1:8853*3-2000,
                    title2:9142*3-2000,
                    title3:2003*3,
                    title4:1433*3,
                    level1:9102*3,
                    level2:8569*3,
                    level3:2806*3,
                    level4:954*3,
                },{
                    major:"工学",
                    total:22555*3-2000,
                    title1:9321*3-1000,
                    title2:9542*3-1000,
                    title3:2106*3,
                    title4:1586*3,
                    level1:9408*3,
                    level2:9102*3,
                    level3:2904*3,
                    level4:1141*3,
                },{
                    major:"农学",
                    total:4287*3,
                    title1:1863*3,
                    title2:1732 *3,
                    title3:452*3,
                    title4:240*3,
                    level1:1832*3,
                    level2:1864*3,
                    level3:325*3,
                    level4:266*3,
                },{
                    major:"医学",
                    total:20819*3,
                    title1:8921*3,
                    title2:8522*3,
                    title3:2036*3,
                    title4:1340*3,
                    level1:8732*3,
                    level2:8623*3,
                    level3:2211*3,
                    level4:1253*3,
                },{
                    major:"军事学",
                    total:345*3,
                    title1:166*3,
                    title2:144*3,
                    title3:25*3,
                    title4:10*3,
                    level1:256*3,
                    level2:13*3,
                    level3:0*3,
                    level4:176*3,
                },{
                    major:"管理学",
                    total:3702*3,
                    title1:1435*3,
                    title2:1342*3,
                    title3:502*3,
                    title4:423*3,
                    level1:1600*3,
                    level2:1523*3,
                    level3:312*3,
                    level4:267*3,
                },{
                    major:"艺术学",
                    total:2959*3,
                    title1:962*3,
                    title2:862*3,
                    title3:602*3,
                    title4:533*3,
                    level1:1003*3,
                    level2:832*3,
                    level3:603*3,
                    level4:123*3,
                }]
                
            }
        },
        created(){
            let arr = [];
            this.tableData.forEach(item=>{
                // item.total *= 3;
                // item.title1 *= 3;
                // item.title2 *= 3;
                // item.title3 *= 3;
                // item.title4 *= 3;
                // item.level1 *= 3;
                // item.level2 *= 3;
                // item.level3 *= 3;
                // item.level4 *= 3;
                console.log(item.total,item.title1 + item.title2 + item.title3 + item.title4)
                if(item.total != item.title1 + item.title2 + item.title3 + item.title4)
                {
                    console.log('错误',item);
                }
            });

        },
        methods: {
            cellStyle({row, column, rowIndex, columnIndex}){
                return {"text-align":'center'}
            }
        },
        components: {

        }
    }
</script>

<style scoped>

</style>
<style>
    .el-table__footer .cell{
        text-align: center;
    }
</style>